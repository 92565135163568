import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element[this.identifier] = this
  }

  trackLogin() {
    gtag('event', 'login', {'method': 'Email'})
  }

  trackSignup() {
    gtag('event', 'sign_up', {'method': 'Email'})
  }

  trackSearch(term) {
    gtag('event', 'search', {'search_term': term})
  }
  
  selectItem(event) {
    let button = event.currentTarget
    let price = parseFloat(button.dataset.price)

    if (button.dataset.name === undefined) {
      console.error("Product doesn't have enough data to track.")
      return
    }

    gtag('event', 'select_item', {
      currency: "EUR",
      items: [{
        item_id: button.dataset.id,
        item_name: button.dataset.name,
        quantity: 1,
        price: price,
        item_manufacturer: button.dataset.manufacturer,
        item_category: button.dataset.category,
        item_properties: button.dataset.properties,
        item_variants: button.dataset.variants
      }]
    })
  }

  addToCart(event) {
    let button = event.currentTarget
    let price = parseFloat(button.dataset.price)

    if (button.dataset.name === undefined) {
      console.error("Product doesn't have enough data to track.")
      return
    }

    gtag('event', 'add_to_cart', {
      currency: "EUR",
      value: price,
      items: [{
        item_id: button.dataset.id,
        item_name: button.dataset.name,
        quantity: 1,
        price: price,
        item_manufacturer: button.dataset.manufacturer,
        item_category: button.dataset.category,
        item_properties: button.dataset.properties,
        item_variants: button.dataset.variants
      }]
    })
  }
}