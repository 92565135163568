import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "min", "max", "option" ]

  filter(event) {
    this.minTarget.value = event.currentTarget.dataset.min
    this.maxTarget.value = event.currentTarget.dataset.max
  }

  reset(event) {
    event.preventDefault()

    // Reset all
    this.optionTargets.forEach(function(option) {
      option.checked = false
    })
    this.minTarget.value = null
    this.maxTarget.value = null
  }

}