import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("price-connected", true, true, null)
    this.element.dispatchEvent(event)
    
    this.element['priceController'] = this
  }

  fetchPricing() {
    fetch(`/p/${this.productId}/price.json`, {
      credentials: 'same-origin'
    }).then(response => response.json())
      .then(function(json) {
        this.updatePrice(json)
      }.bind(this))
  }
  
  updatePrice(json) {
    if (this.element.dataset.productId == json.product_id) {
      this.element.dataset.price = json.price
      if (json.volume_discount) this.element.dataset.volumeDiscount = true
      if (json.base_price) this.element.dataset.basePrice = json.base_price
      if (json.price_per_liter) this.element.dataset.pricePerLiter = json.price_per_liter
      this.stylePrice()
    }
  }

  stylePrice() {
    this.element.innerHTML = `${this.price.match(/^(.*\d*)[,|\.]/)[1]}.<span>${this.price.match(/(\d{2})\s?$/)[1]}</span>`

    if (this.basePrice) {
      this.element.insertAdjacentHTML("afterbegin", `<div class="promotional-price-before">${this.basePrice}</div>`)
      this.element.classList.add('promotional-price')
    }

    if (this.volumeDiscount) {
      this.element.insertAdjacentHTML("afterbegin", `
        <div class="price-prepend">ab</div>
      `)
    }
    
    if (this.pricePerLiter) {
      this.element.insertAdjacentHTML("beforeend", `
        <div style="position: absolute; right: 0; font-size: 11px; font-weight: normal; color: #999; top: 40px">
          (${this.pricePerLiter}/1L)
        </div>
      `)
    }
    
  }
  
  get pricePerLiter() {
    return this.element.dataset.pricePerLiter
  }

  get basePrice() {
    return this.element.dataset.basePrice
  }

  get price() {
    return this.element.dataset.price
  }

  get productId() {
    return this.element.dataset.productId
  }
  
  get volumeDiscount() {
    return this.element.dataset.volumeDiscount
  }

}