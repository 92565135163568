import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  placeholder() {
    this.element.classList.toggle("focused", this.fieldTarget.value.length > 0)
    this.removeErrorStyling()
  }

  removeErrorStyling() {
    this.fieldTarget.parentElement.classList.remove('field_with_errors')
  }

}