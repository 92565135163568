import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "dashboard", "login", "register" ]

  connect() {
    this.highlightLinks()
    this.toggleLogin()
  }

  toggleLogin() {
    if(this.hasDashboardTarget && this.hasLoginTarget) {
      if (this.logged_in) {
        this.dashboardTarget.style.display = 'block'
        this.loginTarget.style.display = 'none'
        if (this.hasRegisterTarget) this.registerTarget.style.display = 'none'
      } else {
        this.dashboardTarget.style.display = 'none'
        this.loginTarget.style.display = 'block'
        if (this.hasRegisterTarget) this.registerTarget.style.display = 'block'
      }
    }
  }

  highlightLinks() {
    this.itemTargets.forEach((el, index) => {
      el.classList.remove('active', 'current')
      
      if (this.ancestor_ids.includes(el.dataset.pageId))
        el.classList.add('active')

      if (this.current_page_id == el.dataset.pageId) 
        el.classList.add('active', 'current')
    })
  }

  get current_page_id() {
    return document.body.dataset.currentPageId
  }

  get ancestor_ids() {
    return document.body.dataset.ancestorIds.split('-').concat([this.ref_id])
  }

  get ref_id() {
    let matches = location.hash.match(/ref=(\d+)/)
    if(matches) return matches[1]
  }

  get logged_in() {
    return document.body.hasAttribute('data-logged-in')
  }

}