import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "option" ]

  connect() {
    document.addEventListener('turbolinks:before-cache', this.close)

    this.element.addEventListener('mouseleave', this.startClosing)
    this.element.addEventListener('mouseenter', this.cancelClosing)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.close)

    this.element.removeEventListener('mouseleave', this.startClosing)
    this.element.removeEventListener('mouseenter', this.cancelClosing)
  }

  startClosing = () => {
    this.closing = window.setTimeout(this.close, 500)
  }

  cancelClosing = () => {
    window.clearTimeout(this.closing)
  }

  close = () => { this.element.classList.remove("open") }

  toggle(event) {
    // Prevent default behaviour
    event.preventDefault()
    event.stopPropagation()

    // Toggle class
    if(this.isOpen) {
      this.close()
    } else {
      this.closeAll()
      this.open(event)
    }
  }

  filter(event) {
    let searchTerm = event.target.value.toLowerCase()
    let options = this.optionTargets.forEach(function(option) {
      let option_label = option.querySelector('span').innerText.toLowerCase()
      let checkbox = option.querySelector('input[type="checkbox"]')

      if ((checkbox && checkbox.checked) || option_label.includes(searchTerm)) {
        option.style.display = 'block'
      } else {
        option.style.display = 'none'
      }
    })
  }

  open(event) {
    this.closeAll()
    this.alignDropdown(event)
    this.element.classList.add("open")
  }

  closeAll() {
    document.querySelectorAll(".dropdown.open").forEach(function(dropdown) {
      dropdown.classList.remove("open")
    })
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  alignDropdown(event) {
    if (event.clientX > window.innerWidth / 1.5) {
      this.element.classList.add("dropdown-align-right")
    } else {
      this.element.classList.remove("dropdown-align-right")
    }
  }

  get isOpen() {
    return this.element.classList.contains("open")
  }

}