import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "score", "stars" ]

  connect() {
    this.setScore(parseInt(this.scoreTarget.value))
  }

  rate(event) {
    event.preventDefault()
    this.setScore(event.currentTarget.dataset.score)
  }

  setScore(score) {
    this.starsTarget.dataset.rating = score / 2
    this.scoreTarget.value = score
  }

}