import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // Delay showing notifications for 0.5 seconds
    setTimeout(function() {
      this.element.classList.add('open')
    }.bind(this), 500)
  }

  close() {
    this.element.classList.remove("open")
  }

}