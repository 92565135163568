import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "locations", "postalCode" ]

  connect() {
    this.getLocations()
    this.cleavePostalCode()
  }

  cleavePostalCode() {
    // Reset cleave instance
    if (this.cleaveInstance) this.cleaveInstance.destroy()
    this.cleaveInstance = new Cleave(this.postalCodeTarget, {blocks: [5], numericOnly: true})
  }

  chooseLocation(event) {
    let button = event.currentTarget
    button.nextElementSibling.checked = true
  }

  getLocations() {
    fetch(`/checkout/ups_access_points?postal_code=${this.postalCodeTarget.value}`, {credentials: "same-origin"})
      .then((response) => response.json())
      .then(function(locations) {
        this.locationsTarget.innerHTML = ""

        locations.forEach(function(location) {
          this.locationsTarget.insertAdjacentHTML('beforeend', `<li>
            ${location.name}

            <button class="button button-primary pull-right" type="submit" data-action="ups-access-points#chooseLocation" />
              <i class="icon icon-arrow-right"></i>
            </button>

            <input type="radio" name="order[ups_access_point]" value="${encodeURIComponent(JSON.stringify(location))}" />

            <div class="pakjegemak-name">
              <small class="text-muted">${location.distance}</small>
            </div>

            <div class="pakjegemak-address">
              ${location.address}, ${location.city}
            </div>
          `)
        }.bind(this))
      }.bind(this))
  }

}