import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "list", "count" ]

  submit(event) {
    event.preventDefault()
    event.stopPropagation()



    fetch(this.formTarget.action, {
      method: "POST",
      headers: {'X-CSRF-Token': Rails.csrfToken()},
      credentials: 'same-origin',
      body: new FormData(this.formTarget)
    }).then(function(response) {
      if (!response.ok) {
        throw new Error("Error")
      }
      return response.text()
    }).then(function(html) {
      this.addComment(html)
    }.bind(this))
    .catch(function(error) {
      alert("Er ging iets mis. Probeer het opnieuw.")
    })
  }

  addComment(html) {
    this.listTarget.insertAdjacentHTML("beforeend", html)
    this.formTarget.parentElement.removeChild(this.formTarget)

    let newCount = this.commentsCount + 1
    this.countTarget.dataset.count = newCount
    this.countTarget.innerText = `(${newCount})`
  }

  get commentsCount() {
    return parseInt(this.countTarget.dataset.count)
  }

}
