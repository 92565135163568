import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    fetch(`/checkout/avondlevering`, {credentials: "same-origin"})
      .then((response) => response.json())
      .then(function(response) {
        this.element.style.display = response.available ? "block" : "none"
      }.bind(this))
  }

}