import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "referralLink", "referralGridItem" ]

  connect() {
    let matches = location.hash.match(/ref=(\d+)/)
    if (matches) {
      let id = matches[1]
      this.fetchReferralLink(id)
      // this.fetchReferralGridItem(id)
    }    
  }

  fetchReferralLink(id) {
    fetch(`/pages/${id}`, {credentials: "same-origin"})
      .then((response) => response.json())
      .then(function(page) {
        if (this.hasReferralLinkTarget) {
          this.referralLinkTarget.innerHTML = `<a class="button button-outline" href="${page.path}">${page.title}</a>`
        }
      }.bind(this))
  }

  fetchReferralGridItem(id) {
    fetch(`/pages/${id}`, {credentials: "same-origin"})
      .then((response) => response.json())
      .then(function(page) {
        this.referralGridItemTarget.insertAdjacentHTML('beforeend', `<a class="grid-item grid-item-explainer explainer-style-2" href="${page.path}">
          <div class="grid-item-explainer-title">
            <h3>Op zoek naar iets vergelijkbaars?</h3>
          </div>

          <div class="grid-item-explainer-action">
            Bekijk alles in <strong>${page.title}</strong>
          </div>
        </a>`)
      }.bind(this))
  }

}