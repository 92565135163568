import { Controller } from "stimulus"
import Swiper from "swiper"

export default class extends Controller {

  // Only enable Swiper for mobile
  connect() {
    if (this.mobile) {
      new Swiper(this.element, {
        slidesPerView: 'auto',
        spaceBetween: 0,
        initialSlide: this.initialSlide,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true
        }
      })
    }
  }

  get initialSlide() {
    let listItem = this.element.querySelector('ul li.swiper-slide.active')
    if (listItem)
      return Array.prototype.indexOf.call(listItem.parentElement.children, listItem)
  }

  get mobile() {
    return document.body.hasAttribute("data-mobile")
  }

}