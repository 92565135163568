import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "list" ]

  connect() {
  }

  submitQuery = debounce(function(query) {
    fetch(`/dashboard/my_e_cigarettes/search`, {
      method: 'post',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json'
      },
      credentials: "same-origin",
      body: JSON.stringify({search: this.inputTarget.value})
    }).then(response => response.text()).then(function(html) {
      this.listTarget.innerHTML = html
    }.bind(this))
  }, 300)

  search(event) {
    this.submitQuery(this.inputTarget.value)
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').content
  }

}