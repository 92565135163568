import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "step", "steps", "navigation", "highlightable" ]

  goToStep(event) {
    event.preventDefault()
    if (this.hasHighlightableTarget && this.highlightableTarget.classList.contains('highlighted')) return
    let link = event.currentTarget

    link.parentElement.querySelectorAll('a').forEach((l) => l.classList.remove('active'))
    link.classList.add('active')
    this.animateStep()
  }

  nextStep() {
    if (!this.hasNavigationTarget) return
    if (this.hasHighlightableTarget && this.highlightableTarget.classList.contains('highlighted')) return
    let next = this.navigationTarget.querySelector('a:not(.active)')
    this.navigationTarget.querySelectorAll('a').forEach((l) => l.classList.remove('active'))
    next.classList.add('active')
    this.animateStep()
  }

  animateStep() {
    let previousStep = this.stepsTarget.querySelector('.active')
    // Do nothing if previous step is same
    if (previousStep == this.currentStep)
      return

    this.stepTargets.forEach((step) => step.classList.remove('active'))
    this.currentStep.classList.add('active')

    let styles = {opacity: 0, right: '-40px'}
    if (this.getIndex(previousStep) < this.getIndex(this.currentStep)) {
      styles = {opacity: 0, left: '-40px'}
    }

    previousStep.style.display = "block"
    previousStep.style.position = "absolute"
    previousStep.style.width = "100%"

    let timeline = new TimelineLite()
    timeline.to(previousStep, .4, styles).to(previousStep, 0, {display: 'none'})

    this.currentStep.style.display = "block"
    this.currentStep.style.opacity = 0
    styles = {opacity: 1, left: '0px'}
    if (this.getIndex(previousStep) < this.getIndex(this.currentStep)) {
      this.currentStep.style.right = "-40px"
      styles = {opacity: 1, right: '0px'}
    } else {
      this.currentStep.style.left = "-40px"
    }
    this.currentStep.style.position = "absolute"
    this.currentStep.style.width = "100%"
    timeline = new TimelineLite()
    timeline.to(this.currentStep, .4, styles)

    let previousBox = previousStep.getBoundingClientRect()
    this.stepsTarget.style.height = `${previousBox.height}px`
    let box = this.currentStep.getBoundingClientRect()
    TweenLite.to(this.stepsTarget, .2, {height: box.height})
  }

  get currentStep() {
    return document.getElementById(this.navigationTarget.querySelector("a.active").dataset.step)
  }

  getIndex = function(element) {
    return Array.from(element.parentNode.childNodes).indexOf(element)
  }

}