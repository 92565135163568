import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "form", "version", "dropdown", "productsOverview", "orderField", "orderLabel", "orderButton", "filters", "explainerDescription", "explainerFilters", "explainerReset", "productsCount" ]

  connect() {
    document.addEventListener('turbolinks:before-cache', this.handleCache)
    this.filter()
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.handleCache)
  }

  submitForm = debounce(function() {
    Rails.fire(this.formTarget, 'submit')
  }, 300)

  handleCache = () => {
    this.productsOverviewTarget.classList.remove('animate')
  }

  filter() {
    this.timestamp() // Timestamp current request
    this.replaceWithPlaceholders()
    this.applyActiveFiltersClasses()

    this.animateProductsOverview()
    this.submitForm() // debounced by 300ms
  }

  order(e) {
    e.preventDefault()

    this.orderFieldTarget.value = e.target.dataset.order // Set hidden field
    this.orderLabelTarget.innerText = e.target.innerText // Set label on button
    this.orderButtonTarget.classList.remove("open") // Close dropdown

    // Filter it
    this.filter()
  }

  timestamp() {
    let timestamp = new Date().getTime()

    this.versionTarget.value = timestamp
    this.productsOverviewTarget.dataset.version = timestamp
  }

  animateProductsOverview() {
    this.productsOverviewTarget.classList.add('animate') // Add animate class for loading animation
  }

  replaceWithPlaceholders() {
    this.productsOverviewTarget.querySelectorAll('.grid-item:not(.grid-item-explainer)').forEach(function(e) {
      e.parentNode.removeChild(e)
    })
    this.productsOverviewTarget.insertAdjacentHTML('beforeend', `<div class="grid-item grid-item-product-placeholder"></div>`.repeat(9))
  }

  resetOne(event) {
    event.preventDefault()
    this.filtersTarget.querySelectorAll(`[data-property="${event.currentTarget.dataset.property}"] input:checked`).forEach(function(input) {
      input.checked = false
    })
    this.filter()
  }

  resetAll(event) {
    event.preventDefault()
    this.filtersTarget.querySelectorAll('input:checked').forEach(function(input) {
      input.checked = false
    })
    this.filter()
  }

  reset(event) {
    event.preventDefault()
    event.currentTarget.parentElement.querySelectorAll('input:checked').forEach(function(input) {
      input.checked = false
    })
    this.filter()
  }

  applyActiveFiltersClasses() {
    // Use a timeout to give it time to remove all other inputs
    setTimeout(function() {
      this.dropdownTargets.forEach(function(dropdown) {
        if (dropdown.querySelectorAll('input:checked').length > 0) {
          let labels = []
          dropdown.querySelectorAll('input:checked + span').forEach(function(element) {
            // Remove linebreaks
            labels.push(element.innerText.replace(new RegExp(/\n/, 'g'), ""))
          })
          dropdown.querySelector('a .dropdown-button-sublabel').innerText = labels.join(', ')
          dropdown.classList.add('filtered')
        } else {
          dropdown.classList.remove('filtered')
        }
      })
      this.copyFiltersToExplainer()
    }.bind(this), 100)
  }

  copyFiltersToExplainer() {
    // If anything is filtered
    if (this.filtersTarget.querySelector('.dropdown.filtered')) {
      this.explainerDescriptionTarget.style.display = 'none'
      this.explainerResetTargets.forEach(function(resetButton) {
        resetButton.style.display = 'block'
      })
      this.explainerFiltersTarget.style.display = 'block'

      this.explainerFiltersTarget.innerHTML = ""

      this.dropdownTargets.forEach(function(dropdown) {
        if(dropdown.classList.contains("filtered")) {
          this.explainerFiltersTarget.insertAdjacentHTML("beforeend", `<li>
            <span>${dropdown.querySelector('a .dropdown-button-sublabel').innerText}</span>
            <a href='#' data-action="filters#resetOne" data-property="${dropdown.dataset.property}">
              &times;
            </a>
          </li>`)
        }
      }.bind(this))
    } else {
      this.explainerDescriptionTarget.style.display = 'block'
      this.explainerResetTargets.forEach(function(resetButton) {
        resetButton.style.display = 'none'
      })
      this.explainerFiltersTarget.style.display = 'none'
    }
  }

}