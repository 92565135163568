import { Controller } from "stimulus"

export default class extends Controller {

  scrollToAnchor(event) {
    event.preventDefault()

    this.anchorElement.scrollIntoView({behavior: 'smooth'}) // smooth scroll only in Chrome
    this.anchorElement.scrollTop -= 100 // 100px offset
  }

  get anchor() {
    return this.element.dataset.anchor
  }

  get anchorElement() {
    return document.querySelector(this.anchor)
  }

}