import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "filtersCount" ]

  connect() {
    this.filtersCountTarget.style.display = 'none'
  }

  toggle() {
    this.formTarget.classList.toggle('opened')
    this.filtersCountBadge()
  }

  filtersCountBadge() {
    this.filtersCountTarget.innerText = this.numberOfFilters

    if (this.numberOfFilters > 0) {
      this.filtersCountTarget.style.display = 'flex'
    } else {
      this.filtersCountTarget.style.display = 'none'
    }
  }

  resetAll() {
    this.filtersCountTarget.style.display = 'none'
  }
  
  back() {
    
  }

  get numberOfFilters() {
    return this.formTarget.querySelectorAll('.dropdown.filtered').length
  }

}