import { Controller } from "stimulus"
import stickybits from 'stickybits'

export default class extends Controller {
  static targets = [ "navigation" ]

  connect() {
    stickybits('nav#primary', {useStickyClasses: true})
    stickybits('.order-widget-container', {useStickyClasses: true})
    stickybits('.order-widget-pagination', {useStickyClasses: true, verticalPosition: 'bottom'})
  }

  click(event) {
    this.closeAllDropdowns()
  }

  closeAllDropdowns() {
    document.querySelectorAll(".dropdown.open").forEach(function(dropdown) {
      dropdown.classList.remove("open")
    })
  }

}