import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "results", "result", "resultName", "suggestionHeader" ]

  submitQuery = debounce(function(url) {
    fetch(url, {credentials: "same-origin"}).then((response) => response.text()).then(function(html) {
      this.resultsTarget.innerHTML = html
      this.resultsTarget.style.display = 'block'
      this.element.classList.add('opened')
      this.suggestionHeaderTarget.innerText = `“${this.inputTarget.value}”`
      this.styleResults()
      this.element.classList.remove('loading')

      // Send search query to Google Analytics
      ga('send', 'pageview', `${url.pathname}${url.search}`)
      document.body.analytics.trackSearch(query)
    }.bind(this))
  }, 1000)

  query() {
    let url = new URL(this.element.dataset.autocomplete)
    let params = {q: this.searchQuery}
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    this.loading()
    this.submitQuery(url)
  }

  styleResults() {
    this.resultNameTargets.forEach(function(result) {
      let html = result.innerHTML
      let regex = new RegExp(`(${this.searchQuery})`, "ig")
      html = html.replace(regex, "<span>$1</span>")
      result.innerHTML = html
    }.bind(this))
  }

  open(event) {
    if (this.inputTarget.value != "") {
      this.element.classList.add('opened')
      this.resultsTarget.style.display = 'block'
    }
  }

  close(event) {
    this.element.classList.remove('opened')
    this.resultsTarget.style.display = 'none'
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  loading() {
    this.element.classList.add('loading')
  }

  get searchQuery() {
    return this.inputTarget.value
  }

}