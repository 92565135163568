import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "navigation", "root", "sublevel" ]

  next(event) {
    let sublevel = event.currentTarget.parentElement.querySelector('ul')
    if (sublevel) {
      event.preventDefault()
      this.rootTarget.style.transform = "translateX(-100%)"
      this.sublevelTargets.forEach(function(sublevel) {
        sublevel.style.display = 'none'
      })
      sublevel.style.display = 'block'
    }
  }

  back() {
    this.rootTarget.style.transform = "none"
  }

  toggle() {
    this.navigationTarget.classList.toggle('opened')
  }

  close(event) {
    if (window.innerWidth - event.pageX < 44) {
      this.navigationTarget.classList.remove('opened')
    }
  }

}