import { Controller } from "stimulus"
import lozad from 'lozad'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "form", "variant", "variants", "image", "variantName", "productIdField", "quantityField", "dropdown", "dropdownLabel", "gallery", "price", "highlightable" ]

  connect() {
    this.styleVariantNames()
    this.highlightAlreadyOrdered()
    this.lazyLoadImages()
  }

  submitForm = debounce(function() {
    Rails.fire(this.formTarget, 'submit')
  }, 300)

  addToCart(e) {
    e.preventDefault()
    this.highlightContainer()
    if (!this.productChosen) return

    // Add to quantity
    let quantity = parseInt(this.quantity) + 1
    this.updateQuantity(quantity)
    this.openAddToCart()
    this.submitForm()
  }

  removeFromCart(e) {
    e.preventDefault()

    // Subtract from quantity
    let quantity = parseInt(this.quantity) - 1
    this.updateQuantity(quantity)
    this.openAddToCart()
    this.submitForm()
  }

  updateQuantity(quantity) {
    // Store old quantity
    let old_quantity = this.quantity

    // Max stockLevel, min 0
    quantity = Math.min.apply(Math, [this.stockLevel, Math.max.apply(Math, [0, quantity])])
    this.quantityFieldTarget.value = quantity

    // Update label if variants
    if (this.hasVariantsTarget) {
      this.currentVariant.dataset.badge = quantity
      if (quantity == 0) this.currentVariant.removeAttribute('data-badge')
    }

    // Fly the image
    if (this.quantity > old_quantity) this.flyImageToCart()
  }

  chooseVariant(e) {
    e.preventDefault()

    if (e.currentTarget.dataset.disabled !== undefined) return

    // Set product ID and stock
    this.productIdFieldTarget.value = e.currentTarget.dataset.productId
    this.productIdFieldTarget.dataset.stockLevel = e.currentTarget.dataset.stockLevel

    // Set quantity field
    this.quantityFieldTarget.value = this.quantityOfProductId(e.currentTarget.dataset.productId)

    // Close dropdown
    if (this.hasDropdownTarget) {
      this.dropdownLabelTarget.innerText = e.currentTarget.querySelector('span').innerText // Set label on button
      this.dropdownTarget.classList.remove("open") // Close dropdown
    } else {
      e.currentTarget.querySelector('input').checked = true
    }

    // Highlight and open cart
    this.highlightContainer()
    this.openAddToCart()

    // Change price element for different product id
    const pricePrepend = this.priceTarget.querySelector(".price-prepend")
    if (this.hasPriceTarget && pricePrepend && pricePrepend.length == 0)
      this.changePrice()

    // Set image
    if (e.currentTarget.dataset.image) this.imageTarget.src = e.currentTarget.dataset.image
  }

  highlightAlreadyOrdered() {
    if (this.productChosen) {
      this.quantityFieldTarget.value = this.quantityOfProductId(this.currentProductId)
      if (this.quantity > 0) this.openAddToCart()
    }
    
    this.variantTargets.forEach(function(variant) {
      let productId = parseInt(variant.dataset.productId)
      let quantity = this.quantityOfProductId(productId)
      if (quantity > 0) variant.dataset.badge = quantity
    }.bind(this))
  }

  highlightContainer() {
    if (this.hasHighlightableTarget) {
      this.highlightableTarget.classList.toggle('highlighted', !this.productChosen)
    }
  }

  // Set new product ID and trigger a new fetch
  changePrice() {
    this.priceTarget.dataset.productId = this.productIdFieldTarget.value
    
    // Trigger custom event
    let event = new Event('change-product-id')
    this.priceTarget.dispatchEvent(event)
  }

  openAddToCart() {
    this.formTarget.classList.toggle('open', this.productChosen && this.quantityFieldTarget.value > 0)
  }

  flyImageToCart() {
    if (!this.hasGalleryTarget && !this.hasImageTarget) return

    let currentImage
    if (this.hasGalleryTarget) {
      let swiper = this.galleryTarget.swiper
      currentImage = swiper.slides[swiper.activeIndex].querySelector('img')
    } else {
      currentImage = this.imageTarget
    }

    let domRect = currentImage.getBoundingClientRect()
    let clone = currentImage.cloneNode()

    // Assign styles
    Object.assign(clone.style, {
      height:   `${domRect.height}px`,
      left:     `${domRect.x}px`,
      position: 'fixed',
      top:      `${domRect.y}px`,
      width:    `${domRect.width}px`,
      zIndex:   200
    })

    // Append to body
    document.body.appendChild(clone)

    // Animate to cart
    let timeline = new TimelineLite()
    timeline.to(clone, .4, {
      height: '65px', 
      left: `${this.domRectCart.x}px`, 
      top: `${this.domRectCart.y}px`, 
      width: '65px'
    }).to(clone, .4, {transform: 'scale(0)'})
  }

  styleVariantNames() {
    this.variantNameTargets.forEach(function(variantName) {
      let name = variantName.innerText
      let nameHTML = name.replace(/(^\d*[\.,]?\d*)(.*$)/g, "$1<small>$2</small>")
      variantName.innerHTML = nameHTML
    })
  }

  quantityOfProductId(productId) {
    return this.cartContents.filter(function(item) {
      return item.orderable_type == "Spina::Shop::Product" && item.orderable_id == productId
    }).reduce((total, item) => total + item.quantity, 0)
  }

  lazyLoadImages() {
    let observer = lozad(this.element.querySelectorAll('.lozad'))
    observer.observe()
  }

  get quantity() {
    return this.quantityFieldTarget.value
  }

  get productChosen() {
    return !!this.currentProductId
  }

  get currentProductId() {
    return this.productIdFieldTarget.value
  }

  get currentVariant() {
    return this.variantsTarget.querySelector(`[data-target="add-to-cart.variant"][data-product-id="${this.currentProductId}"]`)
  }

  get stockLevel() {
    return this.productIdFieldTarget.dataset.stockLevel
  }

  get cart() {
    return document.getElementById('shopping_cart')
  }

  get domRectCart() {
    return this.cart.getBoundingClientRect()
  }

  get cartContents() {
    return JSON.parse(this.cart.dataset.contents)
  }

}