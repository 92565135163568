import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tab" ]

  toggleAll(event) {
    let checkboxes = this.element.querySelectorAll(`.dropdown-filter-checkboxes-group-options[data-pgvg-group="${event.target.value}"] input[type="checkbox"]`)
    
    checkboxes.forEach(function(checkbox) {
      checkbox.checked = event.target.checked
    })
  }

  toggle(event) {
    let group = event.target.parentNode.parentNode
    let allChecked = Array.from(group.querySelectorAll('input[type="checkbox"]')).every(function(checkbox) {
      return checkbox.checked
    })

    // Check allCheckbox
    group.parentNode.querySelector('input[type="checkbox"]').checked = allChecked
  }

  basic(event) {
    event.preventDefault()
    this.element.dataset.state = 'basic'
    this.tabTargets.forEach(function(tab) {
      tab.parentNode.classList.remove('active')
    })
    event.target.parentNode.classList.add('active')
  }

  advanced(event) {
    event.preventDefault()
    this.element.dataset.state = 'advanced'
    this.tabTargets.forEach(function(tab) {
      tab.parentNode.classList.remove('active')
    })
    event.target.parentNode.classList.add('active')
  }

}