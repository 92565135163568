import { Controller } from "stimulus"

export default class extends Controller {

  visitPage(event) {
    if (event.currentTarget.parentElement.classList.contains("active")) {
      event.preventDefault()
      this.element.classList.add('open')
    }
  }

}