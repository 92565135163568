import { Controller } from "stimulus"
import lozad from 'lozad'

export default class extends Controller {
  static targets = [ "image" ]

  connect() {
    this.lazyLoadImages()
  }

  lazyLoadImages() {
    let observer = lozad(this.element.querySelectorAll('img.lozad'))
    observer.observe()
  }

}