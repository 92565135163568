import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    new Cleave(this.fieldTarget, {date: true, datePattern: ['d', 'm', 'Y']})
  }

  change(event) {
    let two_digit_year = this.fieldTarget.value.match(/^\d{2}\/\d{2}\/(\d{2})$/)
    if (two_digit_year) {
      let four_digit_year = `19${two_digit_year[1]}`
      if (two_digit_year[1] < 20)
        four_digit_year = `20${two_digit_year[1]}`

      this.fieldTarget.value = this.fieldTarget.value.replace(/(^\d{2}\/\d{2}\/)\d{2}$/, `$1${four_digit_year}`)
    }
  }

}