import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "row1", "row2", "autocompleteResult", "country", "postalCode", "houseNumber", "houseNumberAddition", "city", "street", "firstName", "lastName", "company" ]

  connect() {
    this.changeCountry()
    this.autocomplete()
  }

  toggleAutocomplete() {
    let row1Field = this.streetTarget.closest('.input-wrapper')
    let row2Field = this.postalCodeTarget.closest('.input-wrapper')

    if (this.isNL && !this.noAutocomplete) {
      row1Field = this.postalCodeTarget.closest('.input-wrapper')
      row2Field = this.streetTarget.closest('.input-wrapper')

      this.row2Target.style.display = 'none'
      this.autocompleteResultTarget.style.display = 'flex'
    } else {
      this.row2Target.style.display = 'flex'
      this.autocompleteResultTarget.style.display = 'none'
    }

    this.row1Target.insertBefore(row1Field, this.row1Target.childNodes[0])
    this.row2Target.insertBefore(row2Field, this.row2Target.childNodes[0])
  }

  disableAutocomplete(event) {
    event.preventDefault()
    this.noAutocomplete = true
    this.toggleAutocomplete()
    this.streetTarget.focus()
  }

  selectFromAddressBook(event) {
    event.preventDefault()

    this.noAutocomplete = true
    this.toggleAutocomplete()

    let dataset = event.currentTarget.dataset
    this.streetTarget.value = dataset.street
    this.cityTarget.value = dataset.city
    this.postalCodeTarget.value = dataset.postalCode
    this.houseNumberTarget.value = dataset.houseNumber

    if (dataset.houseNumberAddition != undefined) this.houseNumberAdditionTarget.value = dataset.houseNumberAddition
    if (dataset.firstName != undefined) this.firstNameTarget.value = dataset.firstName
    if (dataset.lastName != undefined) this.lastNameTarget.value = dataset.lastName
    if (dataset.companyName != undefined) this.companyTarget.value = dataset.company

    let array = [this.streetTarget, this.cityTarget, this.postalCodeTarget, this.houseNumberTarget, this.houseNumberAdditionTarget, this.firstNameTarget, this.lastNameTarget, this.companyTarget]
    array.forEach(function(target) {
      target.dispatchEvent(new Event('change'))
    })
  }

  // Autocomplete 
  // Only for NL addresses
  autocomplete() {
    if (!this.isNL) return
    if (this.postalCodeTarget.value == "" && this.houseNumberTarget.value == "") return
    this.autocompleteResultTarget.innerHTML = '<span class="text-muted">...</span>'
    fetch(`/autocomplete.json`, {
      method: 'post',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json'
      },
      credentials: "same-origin",
      body: JSON.stringify(this.autocompleteParams)
    }).then(response => response.json())
      .then(function(json) {
        if (json.error) {
          this.autocompleteResultTarget.innerHTML = `<span class="text-muted">${json.error}</span> <a href="#" data-action="address#disableAutocomplete">Adres handmatig invoeren</a>`
          this.streetTarget.focus()
        } else if(json.street_name) {
          this.streetTarget.value = json.street_name
          this.streetTarget.closest('.input-wrapper').classList.add('focused')
          this.cityTarget.value = json.city
          this.cityTarget.closest('.input-wrapper').classList.add('focused')
          this.autocompleteResultTarget.innerText = this.fullAddress
        }
      }.bind(this))
  }

  changeCountry() {
    this.cleavePostalCodeField()
    this.toggleAutocomplete()
  }

  cleavePostalCodeField() {
    let params = {blocks: [5], numericOnly: true}

    if (this.isNL) {
      params = {blocks: [4, 2], uppercase: true, delimiter: ' '}
    } else if (this.isBE) {
      params = {blocks: [4], numericOnly: true}
    }

    // Reset cleave instance
    if (this.cleaveInstance) this.cleaveInstance.destroy()
    this.cleaveInstance = new Cleave(this.postalCodeTarget, params)
  }

  get autocompleteParams() {
    return {
      autocomplete: {
        postal_code:            this.postalCodeTarget.value,
        house_number:           this.houseNumberTarget.value,
        house_number_addition:  this.houseNumberAdditionTarget.value
      }
    }
  }

  get isNL() {
    let selectedIndex = this.countryTarget.selectedIndex
    return this.countryTarget.options[selectedIndex].dataset.code == "NL"
  }

  get isBE() {
    let selectedIndex = this.countryTarget.selectedIndex
    return this.countryTarget.options[selectedIndex].dataset.code == "BE"
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').content
  }

  get fullAddress() {
    return `${this.streetTarget.value} ${this.houseNumberTarget.value}${this.houseNumberAdditionTarget.value}, ${this.cityTarget.value}`
  }

}