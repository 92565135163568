import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "product" ]
  
  connect() {
    this.fetchPricing()
  }
  
  priceConnected(event) {
    this.fetchPricing()
  }
  
  fetchPricing = debounce(function() {
    fetch(`/pricing.json`, {
      method: "POST",
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        product_ids: this.productIds
      })
    }).then(response => response.json())
      .then(function(json) {        
        json.forEach(function(pricing) {
          this.updateProductPricing(pricing)
        }.bind(this))
      }.bind(this))
  }, 500)
  
  updateProductPricing(pricing) {
    let priceController = this.getProductElement(pricing.product_id).priceController
    priceController.updatePrice(pricing)
  }
  
  getProductElement(product_id) {
    return this.productTargets.find(function(product) {
      return parseInt(product.dataset.productId) == parseInt(product_id)
    })
  }
  
  get productIds() {
    return this.products.map(function(product) {
      return product.dataset.productId
    })
  }
  
  get products() {
    return this.productTargets.filter(function(product) {
      return product.dataset.price === undefined
    })
  }
  
}