import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "summary" ]

  load() {
    // Start loading
    this.loading()

    fetch(`/current_order`, {
      credentials: 'same-origin'
    }).then(response => response.text())
      .then(function(html) {
        this.summaryTarget.innerHTML = html
      }.bind(this))
  }

  loading() {
    this.summaryTarget.innerHTML = `<div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>`
  }

}