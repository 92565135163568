import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "locations", "postalCode" ]

  connect() {
    this.getLocations()
    this.cleavePostalCode()
  }

  cleavePostalCode() {
    // Reset cleave instance
    if (this.cleaveInstance) this.cleaveInstance.destroy()
    this.cleaveInstance = new Cleave(this.postalCodeTarget, {blocks: [4, 2], uppercase: true, delimiter: ' '})
  }

  chooseLocation(event) {
    let button = event.currentTarget
    button.nextElementSibling.checked = true
  }

  getLocations() {
    fetch(`/checkout/pakjegemak?postal_code=${this.postalCodeTarget.value}`, {credentials: "same-origin"})
      .then((response) => response.json())
      .then(function(locations) {
        this.locationsTarget.innerHTML = ""

        locations.slice(0, 10).forEach(function(location, index) {
          let value = {
            locationCode: location.LocationCode,
            name: location.Name,
            street: location.Address.Street,
            house_number: location.Address.HouseNr,
            postal_code: location.Address.Zipcode,
            city: location.Address.City,
            country: location.Address.Countrycode
          }

          this.locationsTarget.insertAdjacentHTML('beforeend', `<li>
            ${location.Name}

            <button class="button button-primary pull-right" type="submit" data-action="pakjegemak#chooseLocation" />
              <i class="icon icon-arrow-right"></i>
            </button>

            <input type="radio" name="order[postnl_location]" ${(value.locationCode == this.element.dataset.postnlLocation) ? 'checked' : ''} value="${encodeURIComponent(JSON.stringify(value))}" />

            <div class="pakjegemak-name">
              <small class="text-muted">${location.Distance}m</small>
            </div>

            <div class="pakjegemak-address">
              ${location.Address.Street} ${location.Address.HouseNr}, ${location.Address.City}
            </div>

            <table class="pakjegemak-opening-hours">
              <tr>
                <td>Maandag</td>
                <td>${location.OpeningHours.Monday ? location.OpeningHours.Monday.string : 'Gesloten'}</td>
              </tr>
              <tr>
                <td>Dinsdag</td>
                <td>${location.OpeningHours.Tuesday ? location.OpeningHours.Tuesday.string : 'Gesloten'}</td>
              </tr>
              <tr>
                <td>Woensdag</td>
                <td>${location.OpeningHours.Wednesday ? location.OpeningHours.Wednesday.string : 'Gesloten'}</td>
              </tr>
              <tr>
                <td>Donderdag</td>
                <td>${location.OpeningHours.Thursday ? location.OpeningHours.Thursday.string : 'Gesloten'}</td>
              </tr>
              <tr>
                <td>Vrijdag</td>
                <td>${location.OpeningHours.Friday ? location.OpeningHours.Friday.string : 'Gesloten'}</td>
              </tr>
              <tr>
                <td>Zaterdag</td>
                <td>${location.OpeningHours.Saturday ? location.OpeningHours.Saturday.string : 'Gesloten'}</td>
              </tr>
              <tr>
                <td>Zondag</td>
                <td>${location.OpeningHours.Sunday ? location.OpeningHours.Sunday.string : 'Gesloten'}</td>
              </tr>
            </table>
            
          </li>`)
        }.bind(this))
      }.bind(this))
  }

}