import { Controller } from "stimulus"

export default class extends Controller {

  refreshCheckoutSidebar() {
    this.summaryController.fetchSummary()
  }

  get summaryController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector("#sidebar_summary"), "checkout-sidebar-summary")
  }

}