import { Controller } from "stimulus"
import Swiper from "swiper"

export default class extends Controller {
  static targets = [ "gallery", "slide" ]

  connect() {
    this.swiper = new Swiper(this.galleryTarget, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets'
      }
    })
  }

  changeImage(event) {
    let slideIndex = this.slideTargets.findIndex(function(slide) {
      return slide.dataset.imageId == event.currentTarget.dataset.imageId
    })

    this.swiper.slideTo(slideIndex)
  }

}