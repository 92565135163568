import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["country"]

  changeVAT(event) {
    let vatID = event.currentTarget.value
    let match = vatID.match(/^[A-Za-z]{2}/g)
    if (match) {
      this.selectCountryCode(match[0].toUpperCase())
    }
  }

  selectCountryCode(code) {
    let option = this.countryTarget.querySelector(`option[data-code="${code}"]`)

    if (option) {
      this.countryTarget.value = option.value
      this.countryTarget.dispatchEvent(new Event('change'))
    }
  }

}