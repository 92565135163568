import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "required", "country" ]

  connect() {
    this.validateAll()
  }

  validateField(event) {
    let field = event.currentTarget
    this.validate(field)
  }

  validateAll() {
    this.requiredTargets.forEach(function(field) {
      this.validate(field, false)
    }.bind(this))
  }

  validate(field, invalid_labels = true) {
    let wrapper = field.closest('.input-wrapper')
    let valid = false
    let regex = null

    switch(field.dataset.validate) {
      case 'email':
        regex = /\S+@\S+\.\S+/
        valid = regex.test(field.value)
        break
      case 'postal_code':
        if (this.isNL) {
          regex = /^\d{4}\s[A-Z]{2}$/
        } else if (this.isBE) {
          regex = /^\d{4}$/
        } else {
          regex = /^\d{5}$/
        }
        valid = regex.test(field.value)
        break
      default:
        valid = field.value.length > 0
    }

    wrapper.classList.toggle('valid', valid)
    if (invalid_labels) {
      wrapper.classList.toggle('invalid', !valid)
    }
  }

  get isNL() {
    let selectedIndex = this.countryTarget.selectedIndex
    return this.countryTarget.options[selectedIndex].dataset.code == "NL"
  }

  get isBE() {
    let selectedIndex = this.countryTarget.selectedIndex
    return this.countryTarget.options[selectedIndex].dataset.code == "BE"
  }

}